<template>
  <div
    :class="{'deleted': message.deleted}"
    class="e-chat__message"
  >
    <span
      class="e-chat__message__text"
      v-html="message.text"
    />
    <div class="e-chat__message__date">
      <time :datetime="message.createdAt">
        {{ message.createdAt | time }}
      </time>
    </div>
    <IconTrash
      v-if="deletable"
      class="e-chat__message-delete"
      @click="deleteMessage"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import api from 'api'
import IconTrash from 'assets/icons/minus.svg'
export default {
  components: {
    IconTrash
  },
  props: {
    message: {
      default: () => ({}),
      type: Object
    },
    deletable: {
      default: false,
      type: Boolean
    }
  },
  computed: mapGetters(['currentUserChat']),
  methods: {
    deleteMessage () {
      this.$confirm('Nachricht löschen', this.$t('system.warning'), {
        confirmButtonText: this.$t('system.confirm'),
        cancelButtonText: this.$t('system.cancel'),
        type: 'warning'
      }).then(() => {
        api.call('deleteChatMessage', { id: this.message.id, rootId: this.currentUserChat.id })
      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/ChatMessage';
</style>
